import React from "react";
import Layout from "../component/layout";
import MVPProcessComponent from "../component/Services/mvpProcessComponent";
import { graphql } from 'gatsby';
import FooterComponent from "../component/footerComponent";
import EnhanceComponent from "../component/Services/enhanceComponent";
import LetsTalkComponent from "../component/Services/letsTalkComonent";
import ServicesImage from '../images/services_banner-min.jpg'
import ServicesHomeComponent from "../component/Services/servicesHomeComponent";
const CustomDevelopment = (props) =>{
    const {
        data:{
            wpPost:{
                seo,
                serviciesHeader: {
                  servicesHomeTitle,
                  servicesHomeDescription,
                },
                buildMvpSection :{
                    buildMvpSectionDescription,
                    buildMvpSectionTitle,
                    designeAndPropertyDescription,
                    designeAndPropertyTitle,
                    implemantationDescription,
                    implemantationTitle,
                    productIdeaDescription,
                    productIdeaTitle,
                    // supportDescription,
                    // supportTitle,
                    buildMvpSectionImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:buildMvpSectionImage
                        },
                      },
                    },
                    designeAndPropertyImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:designeAndPropertyImage
                        },
                      },
                    },
                    implemantationImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:implemantationImage
                        },
                      },
                    },
                    productIdeaImage :{
                      localFile :{
                        childImageSharp :{
                          gatsbyImageData:productIdeaImage
                        },
                      },
                    },
                    // supportImage :{
                    //   localFile :{
                    //     childImageSharp :{
                    //       gatsbyImageData:supportImage
                    //     },
                    //   },
                    // },
                  },
            },
        },
        pageContext: { title },
    } = props;
    return(
        <Layout seo={seo} title={title}>
                     <ServicesHomeComponent
          servicesBackground={ServicesImage}
          servicesHomeTitle={servicesHomeTitle}
          servicesDescription={servicesHomeDescription}
          isProcess={true}
        />
        <div className="enhance-section-background">
    {/* <ServicesHomeComponent/> */}
<MVPProcessComponent
MVPTitle={buildMvpSectionTitle}
MVPDescription={buildMvpSectionDescription}
MVPBuildImage={buildMvpSectionImage}
isEnhance={true}
isLeft={true}
/>
 <EnhanceComponent
enhanceTitle={productIdeaTitle}
enhanceDescription={productIdeaDescription}
enhanceImage={productIdeaImage}
// isTesting={true}
// isEnhance={true}
/>
 <MVPProcessComponent
MVPTitle={designeAndPropertyTitle}
MVPDescription={designeAndPropertyDescription}
MVPBuildImage={designeAndPropertyImage}
isEnhance={true}
isLeft={true}
/>
 <EnhanceComponent
enhanceTitle={implemantationTitle}
enhanceDescription={implemantationDescription}
enhanceImage={implemantationImage}
// isEnhance={true}
/>
<LetsTalkComponent/>
</div>
<FooterComponent/>
</Layout>
    )
}
export const query = graphql`
  query {
    wpPost(slug: {eq: "custom-development-page"}) {
      seo {
        metaDesc
        title
      }
      serviciesHeader {
        servicesHomeTitle
        servicesHomeDescription
      }
      buildMvpSection {
        buildMvpSectionDescription
        buildMvpSectionTitle
        designeAndPropertyDescription
        designeAndPropertyTitle
        implemantationDescription
        implemantationTitle
        productIdeaDescription
        productIdeaTitle
        supportDescription
        supportTitle
        buildMvpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        designeAndPropertyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        implemantationImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        productIdeaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        supportImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
}
`;
export default CustomDevelopment 
